.sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: var(--sidebar-bg);
    padding: 16px 24px 24px 24px;
}

.title {
    font-size: 16px;
}
.sidebarHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebarBalance .sidebarListLabel, 
.sidebarSwap .sidebarListLabel {
    text-transform: uppercase;
    color: var(--white-60);
}

.navLinks {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--white-60);
    margin-bottom: 41px;
}
.navLinks a {
    display: block;
    text-decoration: none;
    color: var(--white-60);
    margin-bottom: 15px;
}

.actionBtns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin: 13px 13px 47px 0;
}

.sidebarBalance {
    margin-bottom: 36px;
}

.sidebarSwap {
    margin-bottom: 36px;
}

.profileBtn {
    margin-right: 13px;
}

@media (max-width: 768px) {
    .actionBtns {
        flex-flow: column;
        align-items: flex-start;
        gap: 5px;
    }
}