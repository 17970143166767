.table {
    overflow-y: auto;
}

.tableHeader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--table-header-bg);
    box-shadow: inset 0px -1px 0px var(--table-border-color);
    padding: 0 24px;
}

.tableHeader > .tableColumn {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: var(--white-60);
}

.tableBody {
    position: relative;
}

.tableBody .tableColumn {
    color: var(--white-90);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.tableColumn {
    display: flex;
    justify-content: flex-start;
    align-items: center;

}