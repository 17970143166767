.primary {
  position: relative;
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 6px;
  background: var(--primary-btn);
  color: var(--white);
  font-weight: var(--primary-btn-weight);
  font-size: 16px;
  line-height: 24px;
  padding: 8px 20px;
  overflow: hidden;
}

.primary svg {
  margin: 0 6px;
}

.secondary {
  background: transparent;
  border: 1px solid var(--purple);
  padding: 8px 20px 8px 16px;
  flex-basis: content;
  justify-content: center;
  white-space: nowrap;
}

/* hover animation */
.primary:before, .secondary:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition-duration: var(--hover-transition-duration);
  transition-timing-function: linear;
}
.primary:hover:not(:disabled):before,
.secondary:hover:not(:disabled):before {
  opacity: 1;
}

.primary:before {
  border-radius: 6px;
  background: var(--primary-btn-hover);
}

.secondary:before {
  border-radius: 0;
  background: var(--white-7);
}

.primary .children, .secondary .children{
  display: flex;
  align-items: center;
  z-index: 2;
}

.tertiary {
  background: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--tertiary-btn);
  padding: 8px 20px;
}

.tertiary:before {
  background: none;
}

[disabled] {
  opacity: .4;
  cursor: not-allowed;
}
