.header {
  display: flex;
  margin-bottom: 12px;
}

.header .right {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  gap: 1px;
}

.cell {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}