.search {
  margin: 16px 0 18px;
}

.selectCryptoTokensContent {
  width: 100%;
  height: 349px;
  overflow-y: scroll;
  overflow-x: hidden;
}
/* scrollbar */
.selectCryptoTokensContent::-webkit-scrollbar {
  width: 5px;
}
.selectCryptoTokensContent::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}
.selectCryptoTokensContent::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 5px;
}
.selectCryptoTokensContent::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}

.token {
  display: flex;
  width: 100%;
  padding: 6px 0;
  cursor: pointer;
  box-shadow: inset 0 -1px 0 var(--table-border-color);
}