.profileButton {
    background: transparent;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--profile-btn);
    border-radius: 36px;
}

.account {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 12px 7px 20px;

    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0.15px;
    color: var(--white-50);
}

.profileButton:hover .account{
   color: var(--white-90);
}
