.column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.firstColumn {
    display: flex;
    align-items: center;
}

.firstColumn > span:first-of-type {
    margin-right: 16px;
}

.firstColumn > span:last-of-type {
    margin-right: 12px;
}

.firstColumn > div {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
}