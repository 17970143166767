.pageTitle {
    font-family: var( --font-secondary);
    color: var(--white-90);
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    letter-spacing: -0.2px;
}

@media(min-width: 768px) {
    .pageTitle {
        font-size: 48px;
        line-height: 54px;
        letter-spacing: -0.4px;
    }
}
