.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--white-90);
  margin-top: 24px;
  text-align: center;
}
.subTitle {
  font-size: 16px;
  margin-top: 8px;
  text-align: center;
}

.transactionModalBtn {
  margin-top: 24px;
}

.retryBtn {
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  color: var(--retry-btn-color);
  margin-top: 8px;
}
.retryBtn:hover {
  color: var(--retry-btn-color-hover);
  transition-duration: var(--hover-transition-duration);
}
.retryBtn svg {
  margin-right: 8px;
  fill: var(--retry-btn-color);
}
.retryBtn:hover svg{
  fill: var(--retry-btn-color-hover);
  transition-duration: var(--hover-transition-duration);
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content a {
  color: var(--secondary-link);
}

.modalContent {
  background: none;
  padding: 0;
}

.transactionModalContentCard {
  background: var(--grey-7);
  border-radius: 6px;
  width: 100%;
  margin-top: 32px;
}