.slicedString {
  position: relative;
  width: 54px;
  margin-right: 22px;
}

.slicedString p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.slicedString:after {
  content: attr(data-filetype);
  position: absolute;
  left: 100%;
}