.wrapper {
  background-color: var(--background);
  min-height: 100vh;
}
.content {
  padding: 32px 0 198px;
  margin: 0 auto;
  max-width: 1060px;
  width: 100%;
  color: var(--white-70);
  flex: 1;
  min-height: calc(100vh - var(--header-height));
}


@media (max-width: 1150px) {
  .content {
    padding: 32px 24px 80px 24px;
  }
}

@media (max-width: 768px) {
   .content {
     padding: 32px 16px 44px 16px;
   }
}
