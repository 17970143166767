.line {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: var(--white-90);
}
.tooltip {
  margin-left: 10px;
}
