.farmDetailsContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.col:last-child {
    margin-right: 0;
}

.label {
    display: flex;
    gap: 4px;
    color: var(--white-60);
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
}

.value {
    display: flex;
    flex-flow: column nowrap;
    color: var(--white-90);
    font-size: 16px;
    line-height: 24px;
    margin-top: 2px;
}

.value > div {
    justify-content: flex-start;
}

.totalFarmLink {
    margin-left: 4px;
}

.aprWrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: 4px;
}

.apr {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--white-90);
}

@media (max-width: 768px) {
    .farmDetailsContent {
        flex-flow: column;
    }
    .column {
       margin-bottom: 20px;
    }
    .column:last-child {
        margin-bottom: 0;
    }
}