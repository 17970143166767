.link {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.link svg{
    fill: var(--secondary-link);
}
.link:hover svg{
    fill: var(--secondary-link-hover);
}

.linkText {
    color: var(--secondary-link);
    margin-left: 4px;

    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    white-space: nowrap;
}

.label {
    max-width: 224px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 5px;
}

