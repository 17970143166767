.pageHeader {
  display: flex;
  justify-content: space-between;
}

.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 44px 0;
}

.infoLine {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-70);
}

.tab {
  margin-bottom: 28px;
}

.tab .comingSoon {
  right: 8px;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.15px;
}

@media (max-width: 768px) {
  .pageHeader {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 400px) {
  .tab .comingSoon {
    font-size: 1.2rem;
  }
}
