:root {
  /* Master */
  --white: #ffffff;

  /* Neutral */
  --grey-3: #1a1724;
  --grey-5:  #1F1C29;
  --grey-7: #24212d;
  /* for disabled tab */
  --grey-7-opacity-60: rgb(36 33 45 / 60%);
  --grey-9: #282631;
  --grey-11: #2d2a36;
  --grey-13:  #322F3A;
  --grey-15: #36343f;
  --grey-30: rgb(89 87 96 / 30%);
  --grey-60: #A19FA5;
  --grey-90: #E7E7E8;
  --light-grey: #b8aec2;
  --grey-background: #13101D;

  /* White */
  --white-7: rgb(255 255 255 / 7%);
  --white-10: rgb(255 255 255 / 10%);
  --white-15: rgb(255 255 255 / 15%);
  --white-20: rgb(255 255 255 / 20%);
  --white-30: rgb(255 255 255 / 30%);
  --white-40: rgb(255 255 255 / 40%);
  --white-50: rgb(255 255 255 / 50%);
  --white-60: rgb(255 255 255 / 60%);
  --white-70: rgb(255 255 255 / 70%);
  --white-80: rgb(255 255 255 / 80%);
  --white-90: rgb(255 255 255 / 90%);

  --primary-btn: linear-gradient(86.19deg, #600CDC 0%, #C766FF 100%);

  /* Gradients */
  --primary-gradient: linear-gradient(86.19deg, #600CDC 0%, #C766FF 100%);
  --secondary-gradient: linear-gradient(86.19deg, #C766FF 0.01%, #FF7F89 100%);
  --secondary-gradient-text: linear-gradient(86.19deg, #DA99FF 0.01%, #FF99A1 100%);

  /* Box shadow */
  --box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.08);

  /* Primary */
  --purple: #C766FF;
  --purple-30: #492A60;
  --purple-7: #20172C;
  --violet: #600CDC;
  --peach: #FF7F89;

  /* Secondary */
  --secondary-success: #42be65;
  --secondary-error: #fa4d56;
  --secondary-attention: #e9c02d;

  --transparent: rgba(255, 255, 255, 0);

  --scrollbar-thumb-color: #888;
  --scrollbar-thumb-hover-color: #555;

  /* Dapp radar */
  --dappradar-primary-bg: #000D26;
  --dappradar-body-bg-0-level: #021431;
  --dappradar-body-bg-1-level: #041938;
  --dappradar-body-bg-2-level: #0E2240;
  --dappradar-blue-cta: #006CFF;
  --dappradar-blue-cta-hover: #0056CC;
  --dappradar-blue-cta-40: #66A7FF;
  --dappradar-connect-btn-hover: #384D73;
  --dappradar-connect-btn-color: rgb(177 187 206 / 90%);
  --dappradar-connect-btn-color-hover: rgb(228 229 233 / 90%);
  --dappradar-bg-neutral-control-default: #2B426A;
  --dappradar-divider: #163159;
  --dappradar-table-border: #10284B;
  --dappradar-accordion-checked: #001B46;
  --dappradar-skeleton-bg: #182845;
}
