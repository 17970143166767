.default {
  --background: var(--grey-background);
  --step-bg: var(--grey-13);
  --sidebar-bg: var(--grey-5);
  --accordion-bg: var(--grey-5);
  --accordion-details-bg: var(--grey-3);
  --accordion-checked-bg: var(--purple-7);
  --network-badge-bg: var(--grey-5);
  --font-primary: 'Inter', sans-serif;
  --font-secondary: 'Exo 2', sans-serif;
  --secondary-link: #5392ff;
  --secondary-link-hover: #5392ff;
  --secondary-link-9: #181C31;
  --tooltip-bg: var(--grey-13);
  --tooltip-border-color: var(--grey-13);
  --checkbox-checked-color: var(--purple);
  --checkbox-hover-color: var(--violet);
  --hover-transition-duration: .7s;
  --skeleton-bg: var(--grey-11);
  --skeleton-bg-gradient: linear-gradient(to right, #2D2A36 0%, #595760 20%, #2D2A36 40%, #2D2A36 100%);

  --general-modal-border: 1px solid var(--white-10);
  --general-modal-body: var(--grey-5);
  --modal-overlay: rgba(11, 9, 16, 0.85);
  --modal-border: rgba(255, 255, 255, 0.1);
  --web3-modal-bg: var(--grey-5);
  --web3-modal-bg-hover: rgba(195, 195, 195, 0.14);
  --web3-modal-border: 1px solid var( --modal-border);

  --primary-btn: linear-gradient(86.19deg, #8836ff 0%, #b646ef 100%);
  --primary-btn-weight: 500;
  --primary-btn-hover: var(--violet);
  --profile-btn: var(--grey-5);
  --retry-btn-color: var(--purple);
  --retry-btn-color-hover: var(--violet);
  --connect-btn-bg: var(--transparent);
  --connect-btn-bg-hover: var(--white-7);
  --connect-btn-color: var(--white);
  --connect-btn-color-hover: var(--white);
  --tertiary-btn: var(--purple);

  /* Gradients */
  --primary-gradient: linear-gradient(86.19deg, #8836ff 0%, #b646ef 100%);
  --secondary-gradient: linear-gradient(86.19deg, #be5bff 0.01%, #ff8982 100%);

  /* Box shadow */
  --box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.08);

  /* Nodes size */
  --header-height: 68px;
  --header-mobile-height: 60px;
  --buyFarmContainerBottomHeight: 80px;
  --footer-width: 1276px;
  --logo-width: 36px;
  --logo-mobile-width: 36px;
  --logo-height: 36px;

  /* Buy to farm */
  --buy-to-farm-content-bg: linear-gradient(90deg, #1c1926, #1c1926) padding-box, rgba(255, 255, 255, 0.1) border-box;
  --buy-to-farm-farm-details-bg: var(--grey-9);

  /* My Rewards */
  --table-header-bg: var(--grey-7);
  --table-header-selected-bg: var(--grey-13);
  --table-border-color: var(--grey-11);
  --no-content-bg: var(--grey-3);
  --entitle-calc-wrapper-width: 381px;

  /*React toastify*/
  --toast-container-top: 1em;
}

/* partner themes */
.dappradar {
  --background: var(--dappradar-primary-bg);
  --step-bg: var(--dappradar-body-bg-2-level);
  --sidebar-bg: var(--dappradar-body-bg-1-level);
  --accordion-bg: var(--dappradar-body-bg-1-level);
  --accordion-details-bg: var(--dappradar-body-bg-0-level);
  --accordion-checked-bg: var(--dappradar-accordion-checked);
  --font-primary: 'Roboto', sans-serif;
  --font-secondary: 'Poppins', sans-serif;
  --secondary-link: var(--dappradar-blue-cta-40);
  --secondary-link-hover: var(--dappradar-blue-cta);
  --tooltip-bg: var(--dappradar-primary-bg);
  --tooltip-border-color: var(--dappradar-divider);
  --checkbox-checked-color: var(--dappradar-blue-cta);
  --checkbox-hover-color: var(--dappradar-blue-cta-hover);
  --skeleton-bg: var(--dappradar-skeleton-bg);
  --skeleton-bg-gradient: linear-gradient(to right, var(--skeleton-bg) 0%, #304364 20%, var(--skeleton-bg) 40%, var(--skeleton-bg) 100%);
  
  /* modal */
  --general-modal-body: var(--dappradar-body-bg-1-level);
  --general-modal-border: none;
  --web3-modal-bg: var(--dappradar-body-bg-1-level);

  /* button */
  --profile-btn: var(--dappradar-body-bg-1-level);
  --retry-btn-color: var(--dappradar-blue-cta-40);
  --retry-btn-color-hover: var(--dappradar-blue-cta);
  --primary-btn: var(--dappradar-blue-cta);
  --primary-btn-weight: 700;
  --primary-btn-hover: var(--dappradar-blue-cta-hover);
  --connect-btn-bg: var(--dappradar-bg-neutral-control-default);
  --connect-btn-bg-hover: var(--dappradar-connect-btn-hover);
  --connect-btn-color: var(--dappradar-connect-btn-color);
  --connect-btn-color-hover: var(--dappradar-connect-btn-color-hover);
  --tertiary-btn: var(--dappradar-blue-cta-40);

  /* Nodes size */
  --logo-width: 125px;
  --logo-mobile-width: 24px;
  --logo-height: 20px;

  /* Buy to farm */
  --buy-to-farm-content-bg: var(--dappradar-body-bg-1-level);
  --buy-to-farm-farm-details-bg: var(--dappradar-body-bg-2-level);

  /*My Rewards*/
  --table-header-bg: var(--dappradar-body-bg-2-level);
  --table-header-selected-bg: var(--dappradar-divider);
  --table-border-color: var(--dappradar-table-border);
  --no-content-bg: var(--dappradar-body-bg-1-level);
  --entitle-calc-wrapper-width: 355epx;

  --web3-modal-border: none;
  --network-badge-bg: var(--dappradar-body-bg-1-level);
  --web3-modal-bg-hover: var(--dappradar-body-bg-2-level);
}
