.balance {
    height: 36px;
    width: 100%;
    margin-bottom: 12px;
}

.balance > span:last-of-type {
    margin-left: 6px;
}

.iconBox {
    margin-right: 14px;
}

.label {
    color: var(--white-90);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}