.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  display: flex;
  flex-flow: column;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-justify-end {
  display: flex;
  justify-content: flex-end;
}
.w-100 {
  width: 100%;
}

.linkHoverUnderline {
  font-weight: 500;
  color: var(--secondary-link);
}

.linkHoverUnderline:hover {
  color: var(--secondary-link-hover);
  text-decoration-line: underline;
  text-underline-offset: 2px;
  transition-duration: var(--hover-transition-duration);
}
