.tooltipTrigger {
    margin: 0;
    height: 24px;
}

.tooltipAmount {
    font-weight: 600;
}

.tooltipBottom > * {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: 0.2px;
    color: var(--white-90);
}

.tooltipBottom a {
    color: var(--secondary-link)
}

.tooltipBottom button {
    color: var(--secondary-link)
}