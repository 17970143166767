.content {
  width: 100%;
  margin-top: 32px;
}

.contentExtra {
  background: var(--grey-7);
  padding: 24px 36px 36px;
  border-radius: 6px;
  min-height: 232px;
}

.copyLinks {
  margin-top: 12px;
}

.inputClassName {
  padding-right: 100px !important;
}

.step1Content {
  margin-top: 32px;
}

.stepContentText {
    text-align: center;
    max-width: 415px;
    margin: 40px auto 0;
}

.footer {
  width: 100%;
  min-height: 104px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .dissmissBtnHidden {
  visibility: hidden;
}
.footer .dissmissBtn {
  padding-left: 0;
}
.footer .nextBtn {
  margin-left: 16px;
}
.footer .nextBtn svg{
  margin-left: 6px;
  margin-right: 0;
}