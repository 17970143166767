.lockingPeriod {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.15px;
    margin-bottom: 6px;
}
.lockingPeriod svg {
    margin-right: 6px;
}

.text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
}