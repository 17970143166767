.switchRoot {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 36px;
  padding: 8px;
}

.switchInput {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
}

.switchThumb {
  position: absolute;
  display: block;
  background-color: blue;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  top: 3px;
  left: 4px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.swithchThumb::before {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
}

.focusVisible {
  background-color: #79b;
}

checked {
  transform: translateX(24px);
}

.switchTrack {
  background-color: grey;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: block;
}
