@value modalContent from "../../referralFarms/сryptoTokens/modals/selectedFarmModal.module.css";
@value content from "../../buyersFlow/buyersFlowContent/buyersFlowContent.module.css";

.tokenAmount {
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: var(--white-90);
    height: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.lockIcon {
    display: flex;
    order: 1;
}
.amount {
    display: flex;
    order: 2;
}
.tokenSymbol {
    display: flex;
    order: 3;
    margin-left: 4px;
    color: var(--white-60);
    white-space: nowrap;
}

/* referToFarm modal, buyToEarn page */
:where(.modalContent, .content) .tokenSymbol {
    order: 2;
}
:where(.modalContent, .content) .amount {
    order: 1;
}
:where(.modalContent, .content) .lockIcon {
    order: 3;
    margin: 0 4px 0;
}
/* end referToFarm modal, buyToEarn page */