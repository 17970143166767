.input {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 60px;
}

.inputWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.iconStart {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.default {
  color: var(--white-90);
  background-color: var(--grey-9);
  border: 0;
  border-bottom: 1px solid var(--white-20);
  border-radius: 6px 6px 0 0;
  width: 100%;
  height: 100%;
}

/* states */
.default:hover {
  border-bottom: 1px solid var(--white-40);
}
.default:focus {
  border-bottom: 1px solid var(--purple);
}
.default:disabled {
  border-bottom: 1px solid var(--white-10);
  color: var(--white-20);
}
.default::placeholder {
  color: var(--white-30);
}
.default.invalid {
  border-width: 1px;
  border-color: var(--secondary-error);
}
[readonly] {
  color: var(--white-40);
}

.endAdornment {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: var(--white-50);
}

.errorMsg {
  margin-top: 4px;
  color: var(--secondary-error);
}

.label {
  color: var(--white-60);
  margin-bottom: 4px;
}

.helper {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  letter-spacing: 0.015rem;
  color: var(--white-50);
  margin-top: 4px;
}

/* hide arrows from input number  */
/* Chrome, Safari, Edge, Opera */
.default::-webkit-outer-spin-button,
.default::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.default[type='number'] {
  -moz-appearance: textfield;
}

/* autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: var(--grey-9)!important;
  color: var(--white-90)!important;
  -webkit-box-shadow: 0 0 0 30px var(--grey-9) inset !important;
}

