.steps{
    display: flex;
    justify-content: space-between;
}
.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white-40);
}
.activeStep {
    color: var(--white-90);
}
.stepCircle {
    position: relative;
    width: 39px;
    height: 39px;
    border-radius: 40px;
    background: var(--grey-13);
    opacity: 0.4;
    z-index: 2;
}
.activeStep .stepCircle{
    opacity: 1;
}
.stepText {
    margin-top: 8px;
    letter-spacing: 0.15px;
    width: 128px;
    text-align: center;
}
.stepId {
    position: absolute;
    top: -10px;
    left: 1px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: var(--grey-90);
    z-index: 2;

}
.stepIcon {
    display: flex;
    align-items: center;
    z-index: 2;
}
.completeIcon {
    display: flex;
    position: absolute;
    bottom: -5px;
    right: -3px;
    z-index: 2;
}
.stepBg, .stepGradient{
    position: absolute;
    border-radius: 50%;
    background: var(--grey-13);
}
.stepGradient {
    width: 41px;
    height: 41px;
    z-index: 1;
}
.activeStep .stepGradient{
    background: var(--secondary-gradient);
}
.stepBg {
    width: 39px;
    height: 39px;
    z-index: 2;
}
.completeStep .stepGradient {
    background: var(--secondary-success);
}
.step1Content {
    margin-top: 32px;
}