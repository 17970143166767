.root {
  table-layout: fixed;
}

.root table {
  width: 100%;
  border-spacing: 0 1px;
  background-color: var(--grey-11);
  border-radius: 6px;
  overflow: hidden;
}
.root .tableHeader {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  white-space: nowrap;
}

.tableHeader .tableHeaderCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
th:first-of-type .tableHeaderCell {
  justify-content: flex-start;
}

.root td,
.root th {
  position: relative;
  width: auto;
  padding: 13px 16px 13px 0;
}

.root td:first-child,
th:first-child {
  padding-left: 24px;
}

.root td:last-child,
.root th:last-child {
  padding-right: 24px;
}

.root tr > th:first-of-type {
  padding-left: 24px;
  text-align: left;
}

.root table > thead {
  padding-right: 24px;
  height: 54px;
  background-color: var(--grey-7);
}
.root table > tbody {
  background-color: var(--grey-5);
  box-shadow: var(--box-shadow);
}

.tableHeader .tooltip {
  margin-right: 0;
}

@media (max-width: 768px) {
  table {
    border-collapse: collapse;
  }
  table thead {
    clip: rect(0 0 0 0);
    overflow: hidden;
    position: absolute;
  }

  table tr {
    border-bottom: 1px solid rgb(56, 55, 55);
    display: block;
    border-radius: 6px;
    padding-top: 10px;
  }

  table td {
    padding-inline: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    display: flex;
    align-items: center;
  }

  thead {
    display: none;
  }

  .root td,
  .root th, 
  .root td:first-child,
  .root th:first-child,
  .root td:last-child,
  .root th:last-child {
    padding: 13px 16px;
  }
}
