.tokenLogo {
  border-radius: 50%;
  background-color: var(--white-90);
  color: var(--grey-15);
}
.tokenLogo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.lg {
  width: 48px;
  height: 48px;
  font-size: 24px;
}
.md {
  width: 40px;
  height: 40px;
  font-size: 18px;
}
.sm {
  width: 32px;
  height: 32px;
  font-size: 12px;
}
.xs {
  width: 24px;
  height: 24px;
  font-size: 10px;
}
