.noContentWrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 40px 0 44px 0;
    background: var(--no-content-bg)
}
.noContentWrapper img {
    margin-bottom: 20px;
}
.noContentWrapper h2 {
    font-family: var( --font-secondary);
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.1px;
    color: var(--white-90);
    margin-bottom: 8px;
}
.noContentWrapper span {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 24px;
    color: var(--white-70);
    max-width: 400px;
    text-align: center;
}
.noContentWrapper > button{
    margin-top: 32px;
}

/* media */
@media(max-width: 768px) {
    .noContentWrapper {
        padding-left: 8px;
        padding-right: 8px;
    }
}