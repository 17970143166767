.modalBody {
  position: relative;
  padding: 0 16px;
}

.modalHeader {
  justify-content: space-between;
  align-items: baseline;
  min-height: 56px;
  padding-top: 24px;
}

.modalHeader .modalHeaderText {
  display: grid;
  row-gap: 20px;
  width: 100%;
}

.modalHeader > div {
  width: 100%;
}

.modalHeader .modalTitle {
  width: 100%;
  font-family: var( --font-secondary);
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.15px;
  color: var(--white-90);
}

.modalHeaderWithContent {
  padding-bottom: 32px;
}

.modalHeader .modalSubtitle {
  font-size: 16px;
  line-height: 24px;
  opacity: .7;
}
.modalHeader button {
  background: transparent;
  border: 0;
}
.modalContent {
  color: var(--white-70);
}
.modalFooter {
  display: flex;
  justify-content: flex-end;
  min-height: 64px;
}

/* media */
@media (min-width: 768px) {
  .modalBody {
    width: 628px;
    padding: 0 32px;
  }

  .modalFooter {
    min-height: 56px;
  }
}
