.disconnectBtn {
    margin: auto 0 0 0;
    cursor: pointer;
}

.disconnectBtn button {
    color: var(--white);
    opacity: .6;
    font-weight: 500;
    font-size: 14px;
}

.disconnectBtnText {
    margin-left: 8px;
}
