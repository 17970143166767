.tokenLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    background: var(--white-90);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
}

.contentHeader {
    display: flex;
    justify-content: space-between;
    margin-right: 8px;
}

.contentHeaderRight {
    display: flex;
    flex-flow: column nowrap;
    gap: 4px;
    align-items: flex-end;
}

.contentHeader h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--white-90);
    margin-right: 10px;
}

.subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.content {
    background: var(--buy-to-farm-farm-details-bg);
    border-radius: 4px;
    padding: 16px 20px;
    margin: 24px 0;
}

.contentFooter {
    display: flex;
    flex-flow: row wrap;
}

.apr {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--white-90);
}

.invitedBy > span:first-of-type {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    opacity: .6;
}

.invitedByIcon {
    width: 22px;
    height: 22px;
}

.invitedLink {
    margin-left: 8px;
}

.invitedBy img {
    margin: 0 7px 0 9px;
}

.invitedBy .invitedByAccount {
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 500;
    color: var(--white-90);
}

/* skeleton */
.skeleton .contentHeader .subtitle {
    margin-top: 8px;
}

.skeleton .tokenLogo {
    background: none;
}

.skeleton .contentHeaderRight div {
    gap: 8px;
}

.skeleton .content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 20px ;
}

.skeleton .content div {
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
}

.skeleton .contentFooter div:first-of-type {
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    align-items: center;
}

/*end skeleton */

@media(max-width: 768px) {
    .buyFarmBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .buyFarmContainerFixedBottom {
        height: var(--buyFarmContainerBottomHeight);
        padding: 20px 16px;
        border-top: 1px solid var(--white-10);
        background: var(--grey-background);
    }

    .contentHeader {
        flex-flow: column;
        gap: 16px;
    }

    .contentHeaderRight {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    /*skeleton*/
    .skeleton .content {
        display: flex;
        flex-flow: column;
    }

    .skeleton .content div {
        margin-bottom: 20px;
    }
    /*end skeleton*/
}

