.tooltipContentList {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: var(--white-90);
    padding-left: 16px;

    list-style-type: circle;
}
.tooltipContentList li {
    display: list-item;
    list-style: outside;
}

.tooltipContentWithIcon {
    display: flex;
    flex-flow: row nowrap;
    gap: 6px;
}

.tooltipContentWithIcon .icon {
    height: 20px;
    min-width: 16px;
    display: flex;
    align-items: center;
}