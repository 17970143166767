.modalContent {
  background: transparent;
  padding: 0;
}
.modalFooter{
  min-height: 104px;
  align-items: center;
}

.selectTokenModalFooter {
  min-height: 40px;
}

.modalSuccessLink {
  margin-top: 16px;
}
.modalSuccessLink > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 6px;
}

.referralModalTooltip {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: var(--white-90);
  padding-left: 16px;

  list-style-type: circle;
}
.referralModalTooltip li {
  display: list-item;
  list-style: outside;
}
