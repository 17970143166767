.poweredBy {
  color: var(--white-60);
  border-left: 1px solid var(--white-20);
  margin-left: 24px;
  padding-left: 24px;
}
.logo {
  margin-left: 8px;
}
.mobileLogo {
  composes: logo;
  display: none;
}

/* media */
@media (max-width: 768px) {
    .poweredBy {
        margin-left: 12px;
        padding-left: 12px;
    }
    .logo{
      display: none;
    }
    .mobileLogo {
        display: block;
    }
}