:root {
  --toastify-toast-min-height: 38px;
  --toastify-toast-width: 100%;
}

.toast {
  background: var(--grey-5);
  border: 1px solid var(--modal-border);
  border-radius: 6px;
  padding: 8px 16px;
  width: fit-content;
}

.toast:last-of-type {
  margin-bottom: 0;
}



.toastBody {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 22px;
  letter-spacing: 0.15px;
  margin: 0 12px 0 0;
  padding: 0;
}

.toastBody div:last-child {
  flex: initial;
}
