@import './theme.css';
@import './colors.css';
@import './common.css';
@import './web3Modal.css';

html {
  /*62.5% === 10px*/
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 22px;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: 0;
  font-family: var(--font-primary);
}
input:focus,
button:focus {
  outline: none;
}
a {
  text-decoration: none;
  color: var(--white-60);
}
a:hover{
  color: var(--white-90)
}
p {
  margin: 0;
}
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
li {
  list-style: none;
}
