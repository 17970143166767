.comingSoon {
    position: absolute;
    right: 6px;
    top: 2px;
    font-family: var( --font-secondary);
    font-weight: 500;
    font-style: italic;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.2px;
    background: var(--secondary-gradient-text);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.5;
}
