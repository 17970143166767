.nameWrapper {
  margin-left: 12px;
  text-align: left;
}

.name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-90);
  margin-right: 4px;
}

.subname {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--white-60);
  margin-right: 4px;
}

