.row {
  margin-bottom: 10px;
}

.depositTotal {
  font-weight: 600;
  border-top: 1px solid var(--white-15);
  font-weight: 600;
  padding-top: 18px;
  margin-top: 16px;
}

.value {
  font-size: 16px;
  color: var(--white);
}

.errorMessage {
  margin-right: 12px;
}