.pageHeader {
  display: flex;
  justify-content: space-between;
}
.inputs {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 24px 0 18px;
  flex-direction: row;
}
.row {
  line-height: 19px;
}
.rowDisabled {
  opacity: .5;
}

.rowIndex {
  align-self: center;
  margin-right: 20px;
}

.rowText {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.apr {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-90);
}

.infoLine {
  margin: 20px 0 44px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-70);
}

.tab {
  margin-bottom: 28px;
}

.cell {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}

.shortVideo {
  display: inline;
  color: var(--secondary-link)
}

@media (max-width: 768px) {
  .inputs {
    flex-direction: column;
  }
  .selectGroup {
    margin-left: revert;
    width: 100%;
    margin-top: 20px;
  }
  .rowIndex {
    display: none;
  }
  .pageHeader {
    flex-direction: column;
    gap: 20px;
  }
  .row {
    line-height: revert;
  }
}
