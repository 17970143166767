.gradient {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--primary-gradient);
    height: 2px;
}

@media (max-width: 768px) {
    .tabsList .tab {
        padding: 14px 20px;
    }
}