.row{
  display: flex;
  margin-bottom: 32px;
}
.row:last-child {
  margin-bottom: 0;
}
.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  margin-right: 40px;
}
.col:last-child {
  margin-right: 0;
}

.label {
  margin-bottom: 4px;
}
.rewardToken {
  margin-left: 8px;
}
.chainName{
  min-height: 40px;
}

.endAdornment {
  padding-right: 16px;
}