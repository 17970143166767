.dropdown {
  position: relative;
}
.label {
  color: var(--white-50);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
}

.menu {
  display: none;
  position: absolute;
  right: 1px;
  top: 36px;
  width: 202px;
  padding: 8px 0;
  background-color: var(--grey-9);
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.51, 0.92, 0.24, 1);
  z-index: 9999;
}
.menu::before{
  content: "";
  height: 8px;
  background-color:var(--background);
  width: 100%;
  position: absolute;
  top: -8px;
  left: 0;
}

.menuItem {
  padding: 9px 16px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--white-60);
  width: 100%;
}
.menuItem:hover{
  background-color: var(--grey-15);
  color: var(--white-90);
}

.dropdown:hover .menu{
  display: flex;
  flex-direction: column;
}

.dropdown:hover .label{
  color: var(--white-90);
}
.dropdown:hover svg {
  opacity: 0.9;
}

/* media */
@media(max-width: 768px) {
  .menu {
    left: 0;
  }
}

@media (max-width: 370px) {
   .dropdown {
    margin-right: 8px;
    margin-left: 8px;
  }
}