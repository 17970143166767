.nav {
  display: flex;
  align-items: center;
  height: var(--header-height);
}
.nav a,
.subNavLabel {
  position: relative;
  color: var(--white-50);
  margin-right: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.51, 0.92, 0.24, 1);
}
.nav a:last-child {
  margin-right: 0;
}

.nav a:hover, .nav a.active, .subNavLabel:hover, a.active .subNavLabel{
  color: var(--white-90);
}

.nav a.rewardsNav {
  background: var(--secondary-gradient-text);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: .7;
}
.nav a.rewardsNav:hover, .nav a.rewardsNav.active {
  opacity: 1;
}

.subNavLabel {
  height: 40px;
  margin-right: 0;
}
.dropdownMainContent {
  display: flex;
  align-items: center;
}
.dropdownMainContent svg {
  margin-left: 5px;
  margin-top: 2px;
  opacity: .5;
}
.nav a.active svg {
  opacity: .9;
}

@media (max-width: 768px) {
  .nav {
    display: none;
    position: absolute;
    width: 100%;
    top: 54px;
    left: 0;
    background: var(--grey-5);
    padding: 10px 0;
    height: initial;
  }
  .nav a {
    width: 100%;
    text-align: center;
    margin-right: 0;
    padding: 12px 12px 12px 24px;
  }
  .nav a:hover {
    background: var(--grey-11);
    border-radius: 5px;
  }
  .nav a.rewardsNav:hover {
    background: var(--secondary-gradient-text);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .nav a.active::after {
    width: 100%;
    content: '';
    background: none
  }
  .mobileMenuOpen {
    display: flex;
    flex-direction: column;
    z-index: 9999;
  }

  .subNavLabel {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .accordionDetails {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
}