.checkbox {
    display: flex;
    flex-flow: row nowrap;
}

.checkboxText {
    white-space: nowrap;
    margin-left: 12px;
}

.disabledCheckboxText {
    color: var(--grey-30);
}