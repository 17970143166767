.link {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
}

.linkIcon {
    margin-right: 16px;
}

.linkText {
    color: var(--white-90);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.swapLink {
    height: 40px;
}