.footerBtn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 32px 0;
}

.hint {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 24px;
  gap: 6px;
}

.hint a, button {
  color: var(--secondary-link);
}

/* used in tokenAmount css */
.modalContent {}

@media (max-width: 768px) {
  .inputBox {
    width: auto;
    max-width: 100%;
  }
}
@media (max-width: 375px) {
  .inputBox {
    max-width: 130px;
  }
}
