.header {
  height: var(--header-height);
  padding: 16px 20px;
}
.logo {
  width: var(--logo-width);
  height: var(--logo-height);
}
.mobileLogo {
  composes: logo;
  display: none;
}
.mobileMenuBtn {
  display: none;
}

.menuDropdown {
  margin-right: 17px;
}
.rightMenu {
  margin-left: auto;
}
.nav {
  margin-left: 48px;
}
.connectBtn {
  background-color: var(--connect-btn-bg);
  color: var(--connect-btn-color);
}
.connectBtn svg{
  fill: var(--connect-btn-color);
}
.connectBtn:before {
  background-color: var(--connect-btn-bg-hover);
}
.connectBtn:hover {
  color: var(--connect-btn-color-hover);
  transition-duration: var(--hover-transition-duration);
}
.connectBtn:hover svg{
  fill: var(--connect-btn-color-hover);
  transition-duration: var(--hover-transition-duration);
}


@media (max-width: 768px) {
  .mobileMenuBtn {
    display: block;
    width: 30px;
    margin-left: 12px;
  }
  .wrapper {
    position: relative;
  }

  .header {
    padding: 12px 16px;
    height: var(--header-mobile-height);
  }

  .nav {
    margin-left: 0;
  }
  .logo {
    display: none;
  }
  .mobileLogo {
    display: block;
    width: var(--logo-mobile-width);
  }
}

@media (max-width: 370px) {
   .mobileMenuBtn {
    margin-left: 8px;
  }
}
