.videoPopup {
    display: flex;
    position: relative;
    max-width: 860px;
    max-height: 484px;
}

.videoPopup button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -14px;
    right: -14px;
    width: 28px;
    height: 28px;
    background: var(--grey-5);
    border-radius: 32px;
    padding: 0;
    border: none;
}

.videoPopup button:hover {
    cursor: pointer;
}

.videoPopup img {
    width: 20px;
    height: 20px;
}

.videoPopup video {
    width: 100%;
    border-radius: 12px;
    border: 1px solid var(--white-10);
    overflow: hidden;
}

@media (min-width: 768px) {
    .videoPopup {
        width: 860px;
        height: 484px;
    }
}
