.footer {
  display: flex;
  flex-flow: row wrap;
  max-width: var(--footer-width);
  width: 100%;
  margin: 0 auto;
  color: var(--white-70);
  font-size: 14px;
  line-height: 22px;
  border-top: 1px solid var(--grey-7);
  justify-content: space-between;
  padding: 48px 0 56px;
}

.footerLogo {
  margin-bottom: 40px;
}

.logo {
  margin-right: 16px;
}

.logoText h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 4px;
  color: var(--white-80);
}

.left {
  flex: 1;
  min-width: 305px;
}

.right {
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
}

.links {
  display: flex;
  flex-flow: column nowrap;
}

.links h6 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--white-80);
  text-transform: capitalize;
}

.links a {
  display: block;
  text-decoration: none;
  margin-bottom: 15px;
  white-space: nowrap;
}

.links a:last-child {
  margin-bottom: 0;
}

.links a:hover svg {
  opacity: 0.9;
}

.socialLink {
  margin-left: 8px;
}

.right .listLabel {
  font-weight: 700;
}

@media (max-width: 1150px) {
  .footer{
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 480px) {
  .right {
    justify-content: flex-start;
    gap: 60px;
    flex-flow: row wrap;
  }

  .community {
    flex-flow: row nowrap;
    gap: 25px;
    margin-bottom: var(--buyFarmContainerBottomHeight);
  }

  .footer {
    padding-top: 31px;
  }
}