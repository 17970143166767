#WEB3_CONNECT_MODAL_ID .web3modal-modal-lightbox {
  background: var(--modal-overlay);
  backdrop-filter: blur(8px);
  z-index: 1400;
}
#WEB3_CONNECT_MODAL_ID .web3modal-modal-container .web3modal-modal-card {
  background-color: var(--grey-5);
  border: var(--web3-modal-border);
}
#WEB3_CONNECT_MODAL_ID .web3modal-modal-container .web3modal-modal-card .web3modal-provider-wrapper:hover .web3modal-provider-container {
  background-color: var(--web3-modal-bg-hover);
}
#WEB3_CONNECT_MODAL_ID .web3modal-modal-container .web3modal-modal-card .web3modal-provider-container {
  background: var(--web3-modal-bg);
}
#WEB3_CONNECT_MODAL_ID .web3modal-modal-container .web3modal-modal-card .web3modal-provider-name {
  margin-top: 10px;
  font-family: var( --font-primary), sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--white-90);
}
#WEB3_CONNECT_MODAL_ID .web3modal-modal-container .web3modal-modal-card .web3modal-provider-wrapper {
  padding: 0;
  border: 0;
  border-bottom: var(--web3-modal-border);
}
#WEB3_CONNECT_MODAL_ID .web3modal-modal-container .web3modal-modal-card .web3modal-provider-wrapper:last-child {
  border-bottom: 0;
}
#WEB3_CONNECT_MODAL_ID .web3modal-modal-container .web3modal-modal-card .web3modal-provider-container {
  padding: 31px;
  border-radius: 0;
}
#WEB3_CONNECT_MODAL_ID .web3modal-modal-container .web3modal-modal-card .web3modal-provider-description {
  margin: 0;
  font-family: var( --font-primary), sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white-90);
}

#WEB3_CONNECT_MODAL_ID .web3modal-provider-spinner-pending img{
  display: none;
}

#WEB3_CONNECT_MODAL_ID .web3modal-provider-icon #web3modal-provider-spinner {
  display: none;
}

#WEB3_CONNECT_MODAL_ID .web3modal-provider-icon.web3modal-provider-spinner-pending #web3modal-provider-spinner {
  display: block;
}


#walletconnect-wrapper {
  z-index: 99999!important;
}

#walletconnect-wrapper .walletconnect-modal__mobile__toggle a {
  color: var(--grey-9);
}

#web3_modal_install_metamask {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  transform: translateY(-50%);
  top: 86%;
  z-index: 999999;
  color: var(--white);
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

#web3_modal_install_metamask .installMetaMaskBtn {
  width: 500px;
  background: var(--white);
  border-radius: 24px;
  margin-top: 4px;
}

#web3_modal_install_metamask .installMetaMaskBtn a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--grey-9);
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  padding: 13px 38px;
}

#web3_modal_install_metamask .installMetaMaskBtn a img{
  margin-right: 12px;
}

#web3_modal_install_metamask .installMetaMaskBtn a svg {
  margin-left: 4px;
}

@media (max-width: 768px) {
  #web3_modal_install_metamask {
    display: none;
  }
}